import './../App.css';
import logo from './../assets/images/Black-logo-no-background.png';
import desk from './../assets/images/desk.jpg';
import ellipse from './../assets/uinel-assets/images/heroes/elipse.png';
import blue1 from './../assets/uinel-assets/elements/heroes/lines-blue1.svg';
import blue2 from './../assets/uinel-assets/elements/heroes/lines-blue2.svg';
import {Helmet} from "react-helmet";
import favicon from './../assets/images/favicon.ico';
import {Link} from "react-router-dom";

function Home() {
    return (
        <div>
            <Helmet>
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap" rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap" rel="stylesheet" />
                <link rel="shortcut icon" href={favicon} type="image/x-icon" />
                <link rel="icon" href={favicon} type="image/x-icon" />
            </Helmet>
            <div>
                <div className="text-gray-700 font-heading font-medium relative bg-gray-50 bg-opacity-50">
                    <nav className="flex justify-between px-6 lg:px-12 py-8">
                        <div className="flex w-full items-center">
                            <Link to="/">
                                <img className="h-12" src={logo} alt=""/></Link>
                            <ul className="hidden xl:flex px-4 ml-32" style={{display: "none"}}>
                                <li className="mr-16"><a className="text-gray-400 hover:text-gray-500"
                                                         href="oops.html">About</a></li>
                                <li className="mr-16">
                                    <a className="flex items-center text-gray-400 hover:text-gray-500" href="oops.html">
                                        <span className="mr-4">Portfolio</span>
                                        <svg width="8" height="5" viewBox="0 0 8 5" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M6.97291 0.193232C7.20854 -0.0644107 7.58938 -0.0644107 7.82328 0.193232C8.05804 0.450875 8.05978 0.867141 7.82328 1.12478L4.42529 4.80677C4.19053 5.06441 3.81056 5.06441 3.57406 4.80677L0.176073 1.12478C-0.0586909 0.868102 -0.0586909 0.450875 0.176073 0.193232C0.411706 -0.0644107 0.792544 -0.0644107 1.02644 0.193232L4.00098 3.21284L6.97291 0.193232Z"
                                                fill="currentColor"></path>
                                        </svg>
                                    </a>
                                </li>
                                <li><a className="text-gray-400 hover:text-gray-500" href="oops.html">Pricing</a></li>
                            </ul>
                            <div className="hidden xl:flex items-center ml-auto">
                                <a className="text-gray-400 hover:text-gray-500" href="oops.html">
                                </a>


                                <Link to="/contact">
                                    <button
                                        className="uppercase text-sm font-bold font-body border-2 border-gray-200 border-opacity-50 rounded-full py-3 px-5 tracking-wide hover:border-gray-300">
                                        <span className="block mt-px">Contact</span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <button
                            className="navbar-burger self-center xl:hidden text-gray-400 hover:text-gray-500 focus:outline-none">
                            <svg width="25" height="16" viewBox="0 0 25 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect width="25" height="2" fill="currentColor"></rect>
                                <rect y="14" width="25" height="2" fill="currentColor"></rect>
                            </svg>
                        </button>
                    </nav>
                    <div onLoad={setBurgerMenuBehaviour} className="navbar-menu hidden fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-50">
                        <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-80"></div>
                        <nav className="relative flex flex-col py-8 h-full w-full bg-white overflow-y-auto">
                            <div className="flex items-center pl-16 mb-8">
                                <Link className="text-2xl text-gray-800 font-bold" to="/">
                                    <img className="h-12" src={logo} alt=""/></Link>
                            </div>
                            <div>
                                <ul style={{display: "none"}}>
                                    <li className="mb-1 px-10"><a
                                        className="block pl-8 py-4 text-body text-lg rounded-full hover:shadow-2xl"
                                        href="oops.html">About</a></li>
                                    <li className="mb-1 px-10">
                                        <a className="flex items-center pl-8 py-4 text-body text-lg rounded-full hover:shadow-2xl"
                                           href="oops.html">
                                            <span>Portfolio</span>
                                            <svg className="ml-4" width="8" height="5" viewBox="0 0 8 5" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M6.97291 0.193232C7.20854 -0.0644107 7.58938 -0.0644107 7.82328 0.193232C8.05804 0.450875 8.05978 0.867141 7.82328 1.12478L4.42529 4.80677C4.19053 5.06441 3.81056 5.06441 3.57406 4.80677L0.176073 1.12478C-0.0586909 0.868102 -0.0586909 0.450875 0.176073 0.193232C0.411706 -0.0644107 0.792544 -0.0644107 1.02644 0.193232L4.00098 3.21284L6.97291 0.193232Z"
                                                    fill="currentColor"></path>
                                            </svg>
                                        </a>
                                    </li>
                                    <li className="mb-1 px-10"><a
                                        className="block pl-8 py-4 text-body text-lg rounded-full hover:shadow-2xl"
                                        href="oops.html">Pricing</a></li>
                                </ul>
                            </div>
                            <div className="mt-auto px-10">
                                <Link to="/contact">
                                    <button
                                        className="py-3 px-5 mt-6 w-full text-body font-body font-bold uppercase tracking-wide text-sm border-2 border-gray-200 hover:border-gray-300 border-opacity-50 rounded-full">
                                        <span className="block mt-px">Contact</span></button>
                                </Link>
                                <p className="mt-6 mb-4 text-center">
                                    <span className="text-sm text-darkBlueGray-400">2022 © Satori Solutions. All rights reserved.</span>
                                </p>
                            </div>
                        </nav>
                        <button className="navbar-close absolute top-5 p-6 right-5">
                            <div className="absolute top-3">
                                <span className="absolute w-px h-6 bg-black transform -rotate-45"></span>
                                <span className="absolute w-px h-6 bg-black transform rotate-45"></span>
                            </div>
                        </button>
                    </div>
                </div>
                <section className="pb-16 2xl:pb-20 overflow-hidden">
                    <div className="relative bg-blueGray-100 rounded-b-10xl">
                        <div className="container px-4 mx-auto">
                            <div className="flex flex-wrap items-center -mx-4 pt-16 pb-20">
                                <div className="w-full lg:w-1/2 px-4 mb-24 lg:mb-0">
                                    <span
                                        className="block mb-9 font-medium tracking-widest uppercase text-sm text-gray-300">GOOD COMMUNICATION AND QUALITY WORK</span>
                                    <h1 className="max-w-xl mb-16 font-heading font-medium text-9xl md:text-10xl xl:text-13xl leading-none">
                                        <span>A custom made solution for </span>
                                        <span className="relative inline-block">
                      <span className="absolute inset-0 xl:-left-8 xl:-right-8">
                        <img className="absolute w-full max-w-non inset-2"
                             src={blue1} alt=""/><img
                          className="absolute mt-1 lg:mt-2 xl:ml-4 w-full max-w-none inset-3"
                          src={blue2} alt=""/></span>
                      <span className="relative z-10">you</span>
                    </span>
                                    </h1>
                                    <p className="mb-16 lg:mb-24 text-xl text-darkBlueGray-400 leading-snug">Software
                                        development with a personal touch.</p>
                                    <div className="flex flex-col sm:flex-row">
                                        <Link to="/contact">
                                            <button
                                                className="py-5 px-10 mt-3 sm:mt-0 sm:-ml-4 font-heading font-medium tracking-tighter text-xl text-white text-center bg-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 hover:bg-blue-600 rounded-xl">Get
                                                started
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="w-full lg:w-1/2 px-4">
                                    <div className="relative max-w-sm xl:max-w-none mx-auto">
                                        <img className="relative z-10 rounded-5xl" src={desk} alt=""/><img
                                        className="absolute -top-3 -left-24" src={ellipse}
                                        alt=""/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <section className="font-medium rounded-b-10xl py-24 2xl:pt-52 2xl:pb-40">
                <div className="container px-4 mx-auto">
                    <div className="grid md:grid-cols-2 lg:grid-cols-4 justify-center gap-16 xl:gap-40">
                        <div className="max-w-sm mx-auto text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"
                                 className="w-18 h-18 mb-10 mx-auto" viewBox="0 0 16 16">
                                <path
                                    d="M3 6.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 3a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5z"/>
                                <path
                                    d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v1H1V2a1 1 0 0 1 1-1h12zm1 3v10a1 1 0 0 1-1 1h-2V4h3zm-4 0v11H2a1 1 0 0 1-1-1V4h10z"/>
                            </svg>
                            <h3 className="mb-10 font-heading text-5xl leading-loose">Websites</h3>
                            <p className="font-normal leading-loose text-darkBlueGray-400">Landing
                                page<br/>Portfolio<br/>Contact<br/>...
                            </p>
                        </div>
                        <div className="max-w-sm mx-auto text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"
                                 className="w-18 h-18 mb-10 mx-auto" viewBox="0 0 16 16">
                                <path
                                    d="M2.5 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm2-.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm1 .5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
                                <path
                                    d="M2 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H2zm12 1a1 1 0 0 1 1 1v2H1V3a1 1 0 0 1 1-1h12zM1 13V6h4v8H2a1 1 0 0 1-1-1zm5 1V6h9v7a1 1 0 0 1-1 1H6z"/>
                            </svg>
                            <h3 className="mb-10 font-heading text-5xl leading-loose">Webapplications</h3>
                            <p className="font-normal leading-loose text-darkBlueGray-400">Register/Login<br/>E-commerce<br/>Custom
                                functionality<br/>...</p>
                        </div>
                        <div className="max-w-sm mx-auto text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"
                                 className="w-18 h-18 mb-10 mx-auto" viewBox="0 0 16 16">
                                <path
                                    d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
                                <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                            </svg>
                            <h3 className="mb-10 font-heading text-5xl leading-loose">Mobile apps</h3>
                            <p className="font-normal leading-loose text-darkBlueGray-400">Cross platform apps for easy
                                access</p>
                        </div>
                        <div className="max-w-sm mx-auto text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="currentColor"
                                 className="w-18 h-18 mb-10 mx-auto" viewBox="0 0 16 16">
                                <path
                                    d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
                                <path
                                    d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z"/>
                            </svg>
                            <h3 className="mb-10 font-heading text-5xl leading-loose">Support</h3>
                            <p className="font-normal leading-loose text-darkBlueGray-400">Change requests always
                                welcome</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

function setBurgerMenuBehaviour() {
    // open
    const burger = document.querySelectorAll('.navbar-burger');
    const menu = document.querySelectorAll('.navbar-menu');

    if (burger.length && menu.length) {
        for (var i = 0; i < burger.length; i++) {
            burger[i].addEventListener('click', function() {
                for (var j = 0; j < menu.length; j++) {
                    menu[j].classList.toggle('hidden');
                }
            });
        }
    }

    // close
    const close = document.querySelectorAll('.navbar-close');
    const backdrop = document.querySelectorAll('.navbar-backdrop');

    if (close.length) {
        for (let i = 0; i < close.length; i++) {
            close[i].addEventListener('click', function() {
                for (let j = 0; j < menu.length; j++) {
                    menu[j].classList.toggle('hidden');
                }
            });
        }
    }

    if (backdrop.length) {
        for (let i = 0; i < backdrop.length; i++) {
            backdrop[i].addEventListener('click', function() {
                for (let j = 0; j < menu.length; j++) {
                    menu[j].classList.toggle('hidden');
                }
            });
        }
    }
}

export default Home;
