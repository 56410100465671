import './../App.css';
import logo from './../assets/images/Black-logo-no-background.png';
import icon1 from './../assets/uinel-assets/images/contacts/icon1.svg';
import icon2 from './../assets/uinel-assets/images/contacts/icon2.svg';
import icon3 from './../assets/uinel-assets/images/contacts/icon3.svg';

import {Helmet} from "react-helmet";
import favicon from './../assets/images/favicon.ico';
import {NotificationManager} from "react-notifications";
import {Link} from "react-router-dom";

function Home() {
    const showMailSent = () => {
        // NotificationManager.success('Your message has been successfully sent', 'Message sent');
        NotificationManager.error('This feature has not been implemented yet, sorry!', 'Mail not sent')
        console.log('test')
    }

    return (
        <div>
            <Helmet>
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap" rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap" rel="stylesheet" />
                <link rel="shortcut icon" href={favicon} type="image/x-icon" />
                <link rel="icon" href={favicon} type="image/x-icon" />
            </Helmet>
            <div>
                <div class="text-gray-700 font-heading font-medium relative bg-gray-50 bg-opacity-50">
                    <nav class="flex justify-between px-6 lg:px-12 py-8"><div class="flex w-full items-center">
                        <Link to="/">
                            <img class="h-12" src={logo} alt="" /></Link>
                        <ul class="hidden xl:flex px-4 ml-32" style={{display: "none"}}><li class="mr-16"><a class="text-gray-400 hover:text-gray-500" href="oops.html">About</a></li>
                            <li class="mr-16">
                                <a class="flex items-center text-gray-400 hover:text-gray-500" href="oops.html">
                                    <span class="mr-4">Portfolio</span>
                                    <svg width="8" height="5" viewbox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.97291 0.193232C7.20854 -0.0644107 7.58938 -0.0644107 7.82328 0.193232C8.05804 0.450875 8.05978 0.867141 7.82328 1.12478L4.42529 4.80677C4.19053 5.06441 3.81056 5.06441 3.57406 4.80677L0.176073 1.12478C-0.0586909 0.868102 -0.0586909 0.450875 0.176073 0.193232C0.411706 -0.0644107 0.792544 -0.0644107 1.02644 0.193232L4.00098 3.21284L6.97291 0.193232Z" fill="currentColor"></path></svg></a>
                            </li>
                            <li><a class="text-gray-400 hover:text-gray-500" href="oops.html">Pricing</a></li>
                        </ul><div class="hidden xl:flex items-center ml-auto">
                        <button class="uppercase text-sm font-bold font-body border-2 border-gray-200 border-opacity-50 rounded-full py-3 px-5 tracking-wide hover:border-gray-300">
                            <span class="block mt-px">Contact</span>
                        </button>
                    </div>
                    </div>
                        <button class="navbar-burger self-center xl:hidden text-gray-400 hover:text-gray-500 focus:outline-none">
                            <svg width="25" height="16" viewbox="0 0 25 16" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="25" height="2" fill="currentColor"></rect><rect y="14" width="25" height="2" fill="currentColor"></rect></svg></button>
                    </nav><div onLoad={setBurgerMenuBehaviour} class="navbar-menu hidden fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-50">
                    <div class="navbar-backdrop fixed inset-0 bg-gray-800 opacity-80"></div>
                    <nav class="relative flex flex-col py-8 h-full w-full bg-white overflow-y-auto"><div class="flex items-center pl-16 mb-8">
                        <Link class="text-2xl text-gray-800 font-bold" to="/">
                            <img class="h-12" src={logo} alt="" /></Link>
                    </div>
                        <div>
                            <ul style={{display: "none"}}><li class="mb-1 px-10"><a class="block pl-8 py-4 text-body text-lg rounded-full hover:shadow-2xl" href="oops.html">About</a></li>
                                <li class="mb-1 px-10">
                                    <a class="flex items-center pl-8 py-4 text-body text-lg rounded-full hover:shadow-2xl" href="oops.html">
                                        <span>Portfolio</span>
                                        <svg class="ml-4" width="8" height="5" viewbox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.97291 0.193232C7.20854 -0.0644107 7.58938 -0.0644107 7.82328 0.193232C8.05804 0.450875 8.05978 0.867141 7.82328 1.12478L4.42529 4.80677C4.19053 5.06441 3.81056 5.06441 3.57406 4.80677L0.176073 1.12478C-0.0586909 0.868102 -0.0586909 0.450875 0.176073 0.193232C0.411706 -0.0644107 0.792544 -0.0644107 1.02644 0.193232L4.00098 3.21284L6.97291 0.193232Z" fill="currentColor"></path></svg></a>
                                </li>
                                <li class="mb-1 px-10"><a class="block pl-8 py-4 text-body text-lg rounded-full hover:shadow-2xl" href="oops.html">Pricing</a></li>
                            </ul></div>
                        <div class="mt-auto px-10">
                            <Link to="/contact"><button class="py-3 px-5 mt-6 w-full text-body font-body font-bold uppercase tracking-wide text-sm border-2 border-gray-200 hover:border-gray-300 border-opacity-50 rounded-full">
                                <span class="block mt-px">Contact</span></button></Link>

                        <p class="mt-6 mb-4 text-center">
                            <span class="text-sm text-darkBlueGray-400">2022 © Satori Solutions. All rights reserved.</span>
                        </p>
                </div>
                </nav><button class="navbar-close absolute top-5 p-6 right-5">
                <div class="absolute top-3">
                    <span class="absolute w-px h-6 bg-black transform -rotate-45"></span>
                    <span class="absolute w-px h-6 bg-black transform rotate-45"></span>
                </div>
            </button>
            </div>
        </div>
</div>

    <section class="py-24 2xl:pt-28 2xl:pb-36 bg-blueGray-100 rounded-b-10xl"><div class="container px-4 mx-auto">
        <div class="flex flex-wrap -mx-4">
            <div class="w-full lg:w-1/2 px-4 mb-10 lg:mb-0">
                <div class="max-w-lg lg:mt-20 mx-auto">
                    <h2 class="text-9xl md:text-10xl xl:text-11xl font-medium font-heading mb-16 lg:mb-24 leading-tight">Contact us</h2>
                    <div class="max-w-sm py-4 border-b border-gray-200 border-opacity-50">
                        <div class="flex items-center">
                            <img class="mr-6 h-14" src={icon1} alt="" /><a class="text-xl font-semibold" href="#">+ 32 492 ** ** **</a>
                        </div>
                    </div>
                    <div class="max-w-sm py-4 border-b border-gray-200 border-opacity-50">
                        <div class="flex items-center">
                            <img class="mr-6 h-14" src={icon2} alt="" /><a class="text-xl font-semibold" href="#">fb messenger</a>
                        </div>
                    </div>
                    <div class="py-4">
                        <div class="flex items-center">
                            <img class="mr-6 h-14" src={icon3} alt="" /><a class="text-xl font-semibold" href="#">info@satorisolutions.eu</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full lg:w-1/2 px-4">
                <div class="max-w-xl mx-auto py-14 md:pt-16 md:pb-20 px-8 md:px-20 bg-white rounded-xl">
                    <h3 class="font-heading font-medium text-7xl lg:text-8xl mb-12">Message</h3>
                    <input class="mb-5 w-full px-12 py-5 text-xl border-2 border-blue-400 rounded-xl placeholder-darkBlueGray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50" type="email" placeholder="Your Email" /><textarea class="mb-10 w-full px-12 py-5 h-32 text-xl border-2 border-blue-400 rounded-xl placeholder-darkBlueGray-400 resize-none focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50" placeholder="Message" name="" id="" cols="30" rows="10"></textarea>
                        <div className="text-right">
                            <div className="inline-block py-5 px-10 w-full md:w-auto text-xl leading-6 text-white font-medium tracking-tighter font-heading text-center bg-blue-500 hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-xl" onClick={showMailSent}>Send</div>
                        </div>
                </div>
            </div>
        </div>
    </div>
    </section>
        </div>
    );
}

function setBurgerMenuBehaviour() {
    // open
    const burger = document.querySelectorAll('.navbar-burger');
    const menu = document.querySelectorAll('.navbar-menu');

    if (burger.length && menu.length) {
        for (var i = 0; i < burger.length; i++) {
            burger[i].addEventListener('click', function() {
                for (var j = 0; j < menu.length; j++) {
                    menu[j].classList.toggle('hidden');
                }
            });
        }
    }

    // close
    const close = document.querySelectorAll('.navbar-close');
    const backdrop = document.querySelectorAll('.navbar-backdrop');

    if (close.length) {
        for (let i = 0; i < close.length; i++) {
            close[i].addEventListener('click', function() {
                for (let j = 0; j < menu.length; j++) {
                    menu[j].classList.toggle('hidden');
                }
            });
        }
    }

    if (backdrop.length) {
        for (let i = 0; i < backdrop.length; i++) {
            backdrop[i].addEventListener('click', function() {
                for (let j = 0; j < menu.length; j++) {
                    menu[j].classList.toggle('hidden');
                }
            });
        }
    }
}

export default Home;
